.controls {
  display: inline-flex;
  justify-content: space-between;
  border-radius: 10px;
  max-width: 100%;
  margin: auto;
  overflow: hidden;
  position: relative;
}

.controls input {
  opacity: 0;
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  cursor: pointer;
  height: 100%;
  text-align: center;
}

.segment {
  /* width: 100%; uncomment for each segment to have matching width */
  min-width: 120px;
  position: relative;
  text-align: center;
  z-index: 1;
}

.segment label {
  cursor: pointer;
  display: block;
  font-weight: 700;
  padding: 10px;
  transition: color 0.5s ease;
}

.segment.active label {
  color: #fff;
}

.controls::before {
  content: "";
  background: #007bff;
  border-radius: 8px;
  width: var(--highlight-width);
  transform: translateX(var(--highlight-x-pos));
  position: absolute;
  top: 8px;
  bottom: 8px;
  left: 0;
  z-index: 0;
}

/* Only allow transitions once component is ready */
.controls.ready::before {
  transition: transform 0.3s ease, width 0.3s ease;
}

/* Large list button */
.button-container {
  background-color: white;
  border: 0px;
  height: 50px;
  width: 100%;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid rgba(195, 194, 194, 0.656);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button-container:hover {
  background-color: #ededed;
}

.button-body {
  padding: 10px;
}
